:root {
  --main: #97c2d3;
  --secund-opcity: rgba(187, 210, 227, 0.88);
  --secund: rgba(187 210 227, 1);
  --black: #1e272e;
  --bazzi: #e3ded3;
  --overlay-to-bootm: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.6) 0,
    rgba(0, 0, 0, 0) 50%
  );
  --overlay-to-top: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.6) 0,
    rgba(0, 0, 0, 0) 50%
  );

  --gray-opacity: rgba(124 133 139, 0.35);
  --light-text: #fff;
  --dark-text: #1e272e;
  --hover-text-color: #97c2d3;
}

[data-theme="acsess"] {
  --main: #000;
  --secund-opcity: #000;
  --secund: #000;
  --black: #000;
  --bazzi: #fff;
  --overlay-to-bootm: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.1) 0,
    rgba(0, 0, 0, 0) 50%
  );
  --overlay-to-top: linear-gradient(
    to top,
    rgba(0, 0, 0, 1) 0,
    rgba(0, 0, 0, 0) 50%
  );

  --gray-opacity: #000;
  --light-text: #fff;
  --dark-text: #fff;
  --hover-text-color: #97c2d3;
}
