.projects {
  @media screen and (min-width: 600px) {
    .projectsList {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-gap: 1em;
    }
    .project {
      min-height: calc(100vh / 2);
      aspect-ratio: none;
    }
  }

  @media screen and (max-width: 600px) {
    .project {
      aspect-ratio: 1;
      margin-bottom: 1rem;
    }
  }

  .loadMore {
    max-width: 230px;
    margin: 1rem auto;
    background-color: var(--main);
    &:hover {
      background-color: var(--main);
    }
  }
  .project {
    position: relative;
    overflow: hidden;

    &:hover {
      .src-bg {
        transform: scale(1.15);
      }
    }
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      background: var(--overlay-to-top);
    }

    .content {
      z-index: 1;
      color: #000;
      bottom: 0;
      position: absolute;
      transition: 0.25s opacity ease-in-out;

      right: 0;
      padding: 1rem;
      color: #fff;
      font-size: 1.25rem;
      font-weight: 600;
      letter-spacing: 1px;
    }
    .src-bg {
      width: 100%;
      height: 100%;
      position: absolute;
      background-size: cover;
      background-position: 30% center;
      transform: scale(1);
      transition: 0.5s all ease-in-out;

      &.bottom {
        background-position-y: bottom;
      }
    }

    &:nth-child(1) {
      grid-column: 1/3;
      grid-row: 1/3;
    }
    &:nth-child(4) {
      grid-column: 4/2;
      grid-row: 3/5;
    }
    &:nth-child(5) {
      grid-column: 1/3;
      grid-row: 5;
    }
    &:nth-child(6) {
      grid-column: 1;
      grid-row: 6/8;
    }
    &:nth-child(8) {
      grid-column: 2/4;
      grid-row: 6/8;
    }
  }
}
