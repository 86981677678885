@import url("../../swipper.scss");
.ourVision {
  .swiper {
    height: 200px;
  }
  .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .desc {
      padding: 1rem 0 2rem 0;
      line-height: 1.5rem;
      letter-spacing: 1px;
      text-align: justify;
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }

    @media screen and (min-width: 600px) {
      .list {
        padding: 0 2rem;
      }
    }
  }
}
