.CoverImage {
  background-position: 0 0;
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  background-image: url("/assets/cover1.jpg");
  background-size: cover;
  background-position: center bottom;
  @media screen and (max-width: 600px) {
    aspect-ratio: 2;
    height: auto;
  }
  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: var(--overlay-to-bootm);
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
