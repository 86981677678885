@import url("./colors.scss");

$navBar: 52px;

.Accessiblty {
  background: var(--black);
  width: 35px;
  height: 30px;
  position: fixed;
  z-index: 10;
  top: 62px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 0 0 10px;

  > svg {
    fill: #fff;
  }
}
.sectionTitle {
  padding-top: 1rem;
  font-size: 2rem;
  text-align: center;
  max-width: 1200px;
  margin: 1rem auto;
  width: 100%;
  margin-top: 0;
  &.sub {
    font-size: 1.5rem;
    &:before,
    &:after {
      width: 5%;
      letter-spacing: 1px;
    }
  }
  @media screen and (max-width: 600px) {
    font-size: 1.5rem;
    &.sub {
      font-size: 1.25rem;
      &:before,
      &:after {
        width: 10%;
      }
    }
  }

  &:before,
  &:after {
    content: "";
    width: 50%;
    height: 3px;
    display: block;
    right: 0;
    left: 0;
    background-color: var(--main);
    margin: 5px auto;
  }
}
.sectionContainer {
  max-width: 1200px;
  margin: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  padding-top: $navBar;
  margin-top: -($navBar);
  @media screen and (min-width: 600px) {
    min-height: 100vh;
    margin-top: 0;
  }

  &.full {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
.App {
  overflow-x: hidden;
  @media screen and (max-width: 600px) {
    padding-top: $navBar;
  }
}
