.About {
  .Partner {
    width: 100%;
    align-items: stretch;
    margin-bottom: 1rem;
    background-color: var(--main);
    color: #fff;

    &:nth-child(1) {
      flex-direction: row-reverse;
    }

    h3 {
      font-size: 1.5rem;
    }
    .image {
      background-size: cover;
      background-position: center center;
      aspect-ratio: 3/4;
      width: 100%;
      max-width: 327px;
      display: block;
    }
    .desc {
      padding: 1.5rem;
      width: 100%;
      line-height: 1.5rem;
      background-color: var(--black);
    }
    @media screen and (min-width: 600px) {
      display: flex;

      .desc {
        width: 50%;
      }
    }
  }
}
