.card {
  width: 100%;
  min-height: 200px;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
  transition: 0.25s ease box-shadow;
  padding: 1rem;
  background-color: var(--bazzi);
  display: flex;

  h4 {
    width: 100%;
    margin: 0;
    padding-bottom: 1rem;
  }

  &-wrapper {
    width: 100%;
    display: grid;
    min-height: 100%;
    position: relative;
  }

  @media screen and (min-width: 600px) {
    width: calc(33.3% - 1rem);
    &:nth-child(1n) {
      background-color: var(--black);
      color: #fff;
    }
    &:nth-child(2n) {
      background-color: var(--bazzi);
      color: var(--black);
    }

    &:nth-child(3),
    &:nth-child(4) {
      background-color: var(--main);
      color: var(--dark-text);
    }
    &:hover {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      h4 {
        transform: translateY(0);
      }
      .card-content {
        transform: translateY(0);
        opacity: 1;
        position: relative;
        height: auto;
      }
    }
    &-content {
      overflow: hidden;
      position: absolute;
      transform: translateY(100%);
      transition: 0.35s ease all;
      height: 0;
    }
    h4 {
      transform: translateY(50%);
      transition: 0.35s ease all;
      position: relative;
    }
  }
}
