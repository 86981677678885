.NavBar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  margin: 0;
  right: 0;
  left: 0;
  margin: auto;
  height: 52px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    background-color: var(--black);
  }

  &.sticky {
    background-color: var(--black);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  .logo {
    width: auto;
    padding: 5px;
    max-height: 100%;
  }
  .mobile-btn {
    color: #fff;
    height: 100%;
    width: 100%;
    background: none;
    margin: 0;
    align-items: center;
    padding: 0 1rem;
    border: none;
    display: none;
  }
  .NavBarList {
    display: flex;
    align-items: center;
    overflow: hidden;
    height: 100%;
    flex: 1;

    .item {
      a {
        color: #fff;
        font-weight: bold;
        padding-right: 1rem;
        &:hover {
          transition: color 0.25 ease-in-out;
          color: var(--hover-text-color);
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .mobile-btn {
      display: inline-flex;
    }
    .NavBarList {
      flex-direction: column;
      align-items: flex-start;
      background-color: var(--black);
      height: 0;
      transform: translateY(-100%);
      transition: 0.25s all ease-in-out;
      position: absolute;
      width: 100%;
      top: 52px;
      &.open {
        height: auto;
        transform: translateY(0);
        transition: 0.25s all ease-in-out;
      }

      .item {
        width: 100%;
        a {
          height: 50px;
          display: inline-flex;
          width: 100%;
          align-items: center;
        }
      }
    }
  }
}
