@import url("../../swipper.scss");
.OuerValues {
  justify-content: center;
  .swiper {
    height: 350px;
  }
  .cards {
    background-color: var(--main);
    display: flex;
    align-items: stretch;
    text-align: center;
    flex-wrap: wrap;
    margin: auto;
    gap: 0.3rem 0;
  }
  .ValueCard {
    display: inline-flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    padding: 1rem 1rem 2rem 1rem;
    justify-content: center;

    .topSection {
      min-height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      box-sizing: content-box;
      align-items: center;
    }
  }
  .icon {
    & > svg {
      height: 3rem;
      width: 3rem;
      fill: #fff;
    }
  }
  .title {
    font-weight: bold;
    font-size: 1.25rem;
  }
  .desc {
    line-height: 1.5rem;
    height: 50%;
  }
  @media screen and (min-width: 600px) {
    .cards {
      gap: 0.5rem;
      padding: 0.5rem;
    }
    .ValueCard {
      justify-content: space-around;
      width: calc(50% - 0.25rem);
      padding: 0;

      &:nth-child(even) {
        background-color: var(--bazzi);
      }
      &:nth-child(odd) {
        background-color: var(--black);
        color: #fff;
      }
      &:nth-child(3) {
        order: 4;
      }
      .topSection {
        padding: 1rem;
      }
      .desc {
        padding: 0 1rem 1rem 1rem;
      }
    }
  }
}
