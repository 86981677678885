.ContactUs {
  justify-content: center;
  h2 {
    margin-bottom: 3rem;
  }

  .grid {
    display: flex;
    flex-direction: row-reverse;
    height: 400px;
    background-color: var(--bazzi);

    .image {
      width: 50%;
      background-image: url("/assets/vision1.jpg");
      background-size: cover;
      background-position: center;
      position: relative;
      display: none;
    }
    .contact {
      flex: 1;
      background-color: var(--black);
      flex-direction: column;
      padding: 1rem;
      color: #fff;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      letter-spacing: 1px;
      &-ways {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem 0;
        > a {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: #fff;
          width: 100%;
          margin: auto;
          padding: 1rem;
          box-sizing: border-box;
          border-radius: 5px;
          background: var(--main);

          &:hover {
            background: var(--main);
            transition: 0.25s ease;
          }
          > svg {
            padding-left: 5px;
          }
        }
      }
    }
  }
  @media screen and (min-width: 600px) {
    .grid {
      height: 600px;
      .contact {
        &-ways > a {
          background: none;
        }
      }
      .image {
        display: block;
      }
    }
  }
}
