.swiper {
  width: 100%;
  .swiper-slide {
    &:nth-child(1n) {
      > div,
      > li {
        background-color: var(--black);
        color: #fff;
      }
    }

    &:nth-child(2n) {
      > div,
      > li {
        background-color: var(--bazzi);
        color: var(--black);
      }
    }
  }
  .bullet-span {
    background: #fff;
    width: 10px;
    height: 10px;
    display: inline-flex;
    margin-left: 10px;
    border-radius: 10px;
    &-active {
      background: var(--main);
    }
  }
}
