@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  direction: rtl;
  color: var(--black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media screen and (min-width: 600px) {
    font-size: 16px;
  }
}

a {
  text-decoration: none;
  color: initial;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
ul {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
img {
  width: 100%;
}

div {
  box-sizing: border-box;
}
button,
input {
  all: unset;
  &:focus {
    border: 1px solid blue;
  }
}
